import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { MerchantAxiosInstance } from "../../helper/AxiosInstance";
import { AddNoti } from "../../helper/Notification";
import { ParseError } from "../../helper/ResponseHelper";
import { RenderInputCustomType } from "../common/RenderCustomView";

const CreateMerchantCurrency = (props) => {
  const [inputs, setInputs] = useState(
    props.data.map(({ value = "", defaultValue = "" }) => {
      if (!["", null, undefined].includes(value)) {
        return value;
      } else if (!["", null, undefined].includes(defaultValue)) {
        return defaultValue;
      }
      return "";
    })
  );

  const [loading, setLoading] = useState(false);
  const [formData, setFormDatas] = useState(props.data);

  const [image, setImage] = useState(null);

  useEffect(() => {
    if (image !== null) {
      const result = UploadFileApi(image);
      if(result !== null) {
        setImage(result);
      }
    }
  }, [image])

  const UploadFileApi = async (data) => {
    MerchantAxiosInstance.post(`${process.env.REACT_APP_USER_API_ROOT}/uploadAsset/upload`, data, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }
    })
      .then((resp) => {
        console.log("🚀 ~ .then ~ resp:", resp)
        if (resp.data.statusCode === 200 || resp.data.statusCode === 201) {
          return resp.data.data.resultPath;
        } else {
          return null;
        }
      })
      .catch((e) => {
        return null;
      })
      .finally(() => {
        return null;
      });

  };

  const PostApi = (path, data, cb) => {
    MerchantAxiosInstance.post(path, data, {
      headers: {
        'Accept': 'application/json',
      }
    })
      .then((resp) => {
        if (resp.status === 200 || resp.status === 201) {
          AddNoti(resp.data.message, { type: "info" });
        } else {
          AddNoti(ParseError(resp), { type: "error" });
        }
      })
      .catch((e) => {
        AddNoti(ParseError(e), { type: "error" });
      })
      .finally(() => {
        cb();
      });
  };

  const _postApi = PostApi;

  useEffect(() => {
    setFormDatas(props.data);
  }, [props.data]);

  const setInput = (i, v) => setInputs(Object.assign([...inputs], { [i]: v }));
  const setFormData = (i, v) =>
    setFormDatas(Object.assign([...formData], { [i]: v }));

  const handleImage = (i, e) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      console.log("🚀 ~ handleImage ~ e.target:", e.target.files)
      const formData = new FormData();
      formData.append(
        "image",
        e.target.files[0],
        e.target.files[0].name
      );
      // setInput(i, e);
      setImage(formData);
    } else {
      setImage(null);
    }
  };

  const handleFormSubmit = async () => {
    setLoading(true);

    const payload = props.data.reduce((acc, { name }, i) => {
      const query = {};
      if (name === 'image') {
        query[name] = ""
      }
      else if(inputs[i] && name !== 'image') {
        query[name] = inputs[i];
      }

      return { ...acc, ...query };
    }, {})

    _postApi(
      props.api,
      payload,
      () => {
        if (props.cb && typeof props.cb === "function") props.cb();
        setLoading(false);
      }
    );
  }

  return (
    <div
      className={`custom-input-1 dynamic-form ${props.view === "horizontal" ? "" : "container"
        }`}
    >
      {formData.map(({ name, type, ...rest }, i) => (
        <>
          {(
            <RenderInputCustomType
              field={name}
              type={type}
              value={inputs[i]}
              key={i}
              setInput={(i, e) => setInput(i, e)}
              onChange={
                name === "image"
                  ? (e) => setInput(i, e)
                  // ? (e) => handleImage(i, e)
                  : (e) => setInput(i, e)
              }
              formData={formData}
              formValues={inputs}
              setFormData={setFormData}
              view="vertical"
              {...rest}
            />
          )}

        </>
      ))}

      <Button
        onClick={handleFormSubmit}
        variant="primary"
        style={{ width: "10rem", float: "right" }}
        disabled={loading}
      >
        Submit
      </Button>
    </div>
  );
};

export default CreateMerchantCurrency;
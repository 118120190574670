import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import MerchantKeyTable from "./MerchantKeyTable";
import AgentKeyTable from "./AgentKeyTable";
import ConfigKeyTable from "./ConfigKeyTable";
import SettlementKeyTable from "./SettlementKeyTable";
import MerchantCurrencies from "./MerchantCurrencies";
import ApmConfigKeyTable from "./ApmConfigKeyTable";

class MerchantAdminKeyDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      merchantKeyDetail: this.props.location.state.data || {},
      activeTab:"general"
    };
  }

  render() {
    return (
      <div className="kyb main-panel">
        <Card className="custom-card-1">
          <div className="d-flex justify-content-between align-items-center">
						<div className="title">
							Merchant Key Details
							<div className="title--bottom"></div>
						</div>
						<div className="mr-4">
            <Link to="/merchant-admin-keys">Merchant Key </Link> /<span style={{ color: "grey"}}> {this.state.merchantKeyDetail.uniqueId} </span>
            </div>
					</div>
          {/* Adding Merchant Child Links */}
          <div style={{ marginLeft: "20px", marginTop: "15px"}}>
            {/* <Link to={`/merchant-admin-keys-detail/${this.state.merchantKeyDetail.uniqueId}`} className="btn btn-dark" style={{ marginRight: "10px"}}>General</Link> */}
            <button onClick={()=>this.setState({ activeTab: "general"})} className={`btn btn-outline-dark tab-btn mr-2 ${this.state.activeTab === 'general' ? "active" : ""}`}>General</button>
            <button onClick={()=>this.setState({ activeTab: "agent"})} className={`btn btn-outline-dark tab-btn mr-2 ${this.state.activeTab === 'agent' ? "active" : ""}`}>Agent Config</button>
            <button onClick={()=>this.setState({ activeTab: "fee"})} className={`btn btn-outline-dark tab-btn mr-2 ${this.state.activeTab === 'fee' ? "active" : ""}`}>Fee Config</button>
            <button onClick={()=>this.setState({ activeTab: "settlement"})} className={`btn btn-outline-dark tab-btn mr-2 ${this.state.activeTab === 'settlement' ? "active" : ""}`} >Settlement</button>
            <button onClick={()=>this.setState({ activeTab: "currencies"})} className={`btn btn-outline-dark tab-btn mr-2 ${this.state.activeTab === 'currencies' ? "active" : ""}`} >Currencies</button>
            <button onClick={()=>this.setState({ activeTab: "apm-configs"})} className={`btn btn-outline-dark tab-btn ${this.state.activeTab === 'apm-configs' ? "active" : ""}`} >APM Configs</button>
          </div>
        
          <div className="card-body">
            {this.state.activeTab === 'general' && (
              <MerchantKeyTable merchantKeyDetail={this.state.merchantKeyDetail} />
            )}
            {this.state.activeTab === 'agent' && (
              <AgentKeyTable merchantKeyDetail={this.state.merchantKeyDetail} />
            )}
            {this.state.activeTab === 'fee' && (
              <ConfigKeyTable merchantKeyDetail={this.state.merchantKeyDetail} />
            )}
            {this.state.activeTab === 'settlement' && (
              <SettlementKeyTable merchantKeyDetail={this.state.merchantKeyDetail} />
            )}
            {this.state.activeTab === 'currencies' && (
              <MerchantCurrencies merchantKeyDetail={this.state.merchantKeyDetail} />
            )}
            {this.state.activeTab === 'apm-configs' && (
              <ApmConfigKeyTable merchantKeyDetail={this.state.merchantKeyDetail} />
            )}

            {/* <Row>
              <Col lg={12} sm={12} md={12}>
                <ViewOnlyForm data={this.state.merchantKeyDetail} />
              </Col>
            </Row> */}
          </div>
        </Card>
      </div>
    );
  }
}

export default MerchantAdminKeyDetail;
